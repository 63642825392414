import Grid from "@material-ui/core/Grid";
import {Paper} from "@material-ui/core";
import React, {useState} from "react";
import Candidate from "./candidate";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

export interface CandidateInfo {
    id: string,
    name: string,
    avatar: string
}

export interface BallotProps {
    ballotId: string;
    candidates: CandidateInfo[];
    onElect: (id: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ballot: {
            padding: theme.spacing(1),
            backgroundColor: "#E7E5D0"
        },
        candidateList: {
            padding: theme.spacing(1),
            flexGrow: 1
        },
        candidateContainer: {
            margin: theme.spacing(1),
        },
        header: {
            textTransform: "uppercase",
            fontFamily: "Helvetica"
        },
    }),
);

const toState =
    (props: BallotProps) => {
        return {
            ballotId: props.ballotId,
            candidates: props.candidates.map(c => ({...c, enabled: true, selected: false})),
            selectionDone: false
        }
    }

export default function Ballot(props: BallotProps) {
    const classes = useStyles();

    const [ballotState, setBallotState] = useState(toState(props));

    console.log(props.candidates.length, ballotState.candidates.length);

    if (props.ballotId !== ballotState.ballotId) {
        console.log("Refresh state");
        setBallotState(toState(props));
    }

    const onCandidateSelected = (id: string) => {
        const candidates = ballotState.candidates.slice();

        candidates.forEach(c => {
            c.enabled = false;
            c.selected = c.id === id;
        });

        setBallotState({
            ...ballotState,
            candidates,
            selectionDone: true
        });

        props.onElect(id);
    }

    return (
        <Paper className={classes.ballot} lang="tr">
            <Grid container direction={"column"}>
                <Grid item>
                    <Typography className={classes.header} variant={"h5"} align={"center"}>
                        Başkan Adayları
                    </Typography>
                </Grid>
                <Grid container direction={"row"} justify={"center"} alignItems={"stretch"}
                      className={classes.candidateList}>
                    {
                        ballotState.candidates.map((candidate) => (
                            <Grid item className={classes.candidateContainer} key={candidate.id}>
                                <Candidate key={ballotState.ballotId + "#" + candidate.id} id={candidate.id} ballotId={ballotState.ballotId} name={candidate.name}
                                           avatar={candidate.avatar} selected={candidate.selected}
                                           enabled={candidate.enabled}
                                           onSelected={onCandidateSelected}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
            </Grid>
        </Paper>
    )
}
