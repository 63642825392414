import React, {useEffect, useState} from "react";
import {CampaignsService, Candidate, CandidatesService} from "../api";
import {useParams} from 'react-router-dom'
import Grid from "@material-ui/core/Grid";
import {
    Avatar,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import {ArrowLeft, ArrowRight} from "@material-ui/icons";
import NavBar from "../layout/navbar";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        viewRoot: {
            backgroundColor: theme.palette.secondary.main,
            justifyContent: "center",
            padding: theme.spacing(1),
            position: 'relative'
        },
        containerGrid: {
            width: "fit-content"
        },
        progressContainer: {
            position: 'absolute',
            justifyContent: "center",
            alignItems: "center",
        },
        table: {
            backgroundColor: theme.palette.primary.main,
            minWidth: 650,
        },
    }),
);

export interface CampaignCandidatesViewProps {
    user: string;
}

type CandidateItemState = Candidate & { busy?: boolean }

interface CandidatesState {
    selectedCandidates: CandidateItemState[];
    unselectedCandidates: CandidateItemState[];
    isLoading: boolean;
}

export function CampaignCandidatesView({user}: CampaignCandidatesViewProps) {
    const classes = useStyles();
    const {campaignId} = useParams<{ campaignId: string }>();

    const [candidatesState, setCandidatesState] = useState<CandidatesState>({
        selectedCandidates: [],
        unselectedCandidates: [],
        isLoading: false
    });

    const loadCandidates = async (campaignId: string, user: string) => {
        setCandidatesState(s => ({...s, isLoading: true}));
        const selectedCandidatesPromise = CampaignsService.getCampaignsCampaignIdCandidates({campaignId});
        const allCandidatesPromise = CandidatesService.getCandidates({user});

        const selected: Candidate[] = [];
        const unselected: Candidate[] = [];

        try {
            await Promise.all([selectedCandidatesPromise, allCandidatesPromise]);
            const selectedCandidates = (await selectedCandidatesPromise).candidates;
            const allCandidates = (await allCandidatesPromise).candidates;

            allCandidates.forEach(c => {
                selectedCandidates.some(sc => sc.candidateId === c.id) ? selected.push(c) : unselected.push(c);
            });
        } catch (e) {
            console.error(`Failed to load candidates for campaign id ${campaignId}.`, e);
        }
        setCandidatesState(s => ({
            ...s,
            selectedCandidates: selected,
            unselectedCandidates: unselected,
            isLoading: false
        }));
    }
    useEffect(() => {
        loadCandidates(campaignId, user).finally();
    }, [campaignId]);

    return (
        <>
            <NavBar/>
            <Grid container direction={"row"} className={classes.viewRoot}>
                <Grid item>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Avatar</TableCell>
                                    <TableCell align="left">Ad</TableCell>
                                    <TableCell align="left">{""}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {candidatesState.unselectedCandidates.map((row, index) => (
                                    <TableRow key={row.id} style={row.busy ? {pointerEvents: "none", opacity: "0.4"} : {}}>
                                        <TableCell align="right">
                                            <Avatar variant={"rounded"} src={row.avatar}/>
                                        </TableCell>
                                        <TableCell align="left">{row.name}</TableCell>
                                        <TableCell align="left">
                                            <IconButton color="secondary" aria-label="add to campaign" component="span">
                                                <ArrowRight onClick={() => {
                                                    row.busy = true;
                                                    setCandidatesState(s => ({...s}));

                                                    CampaignsService.putCampaignsCampaignIdCandidatesCandidateId({
                                                        campaignId,
                                                        candidateId: row.id,
                                                        user
                                                    })
                                                        .then(() => {
                                                                candidatesState.unselectedCandidates.splice(index, 1);

                                                                candidatesState.selectedCandidates.push(row);
                                                                row.busy = false;
                                                                setCandidatesState(s => ({...s}));
                                                            },
                                                            (e) => {
                                                                console.error(`Failed to register candidate ${JSON.stringify(row, null, 2)}.`, e);
                                                                row.busy = false;
                                                                setCandidatesState(s => ({...s}));
                                                            });
                                                }}/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">{""}</TableCell>
                                    <TableCell align="left">Avatar</TableCell>
                                    <TableCell align="left">Ad</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {candidatesState.selectedCandidates.map((row, index) => (
                                    <TableRow key={row.id} style={row.busy ? {pointerEvents: "none", opacity: "0.4"} : {}}>
                                        <TableCell align="left">
                                            <IconButton color="secondary" aria-label="delete campaign" component="span">
                                                <ArrowLeft onClick={() => {
                                                    row.busy = true;
                                                    setCandidatesState(s => ({...s}));

                                                    CampaignsService.deleteCampaignsCampaignIdCandidatesCandidateId({
                                                        campaignId,
                                                        candidateId: row.id,
                                                    }).then(() => {
                                                            candidatesState.selectedCandidates.splice(index, 1);
                                                            candidatesState.unselectedCandidates.push(row);
                                                            row.busy = false;
                                                            setCandidatesState(s => ({...s}));
                                                        },
                                                        (e) => {
                                                            console.error(`Failed to register candidate ${JSON.stringify(row, null, 2)}.`, e);
                                                            row.busy = false;
                                                            setCandidatesState(s => ({...s}));
                                                        });
                                                }}/>
                                            </IconButton>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Avatar variant={"rounded"} src={row.avatar}/>
                                        </TableCell>
                                        <TableCell align="left">{row.name}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </>
    );
}
