import Grid from "@material-ui/core/Grid";
import Ballot, {CandidateInfo} from "./ballot";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import NavBar from "../layout/navbar";
import React, {useEffect, useState} from "react";

import {CircularProgress} from "@material-ui/core";
import {CampaignsService, CandidatesService} from "../api";

interface BallotData {
    id: string;
    candidates: CandidateInfo[];
    token: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        viewRoot: {
            backgroundColor: theme.palette.secondary.main,
            justifyContent: "center",
            alignItems: "center",
            padding: theme.spacing(1),
            position: 'relative'
        },
        containerGrid: {
            width: "fit-content"
        },
        progressContainer: {
            position: 'absolute',
            justifyContent: "center",
            alignItems: "center",
        }
    }),
);

const postBallotVote = async (campaignId: string, user: string, ballotId: string, voted: string, token: string): Promise<void> => {
    await CampaignsService.postCampaignsCampaignIdBallotsBallotIdVote({
        ballotId,
        user,
        campaignId,
        requestBody: {token, votedCandidate: voted}
    });
}

const loadBallotData = async (campaignId: string, user: string): Promise<BallotData> => {
    const ballot = await CampaignsService.postCampaignsCampaignIdBallots({campaignId, user});

    const candidatesResponse = await CandidatesService.getCandidates(
        {user, ids: ballot.ballot.candidates.join(',')}
    );

    return {id: ballot.ballot.id, candidates: candidatesResponse.candidates, token: ballot.token};
}

export default function BallotView({userId}: { campaignId: string, userId: string }) {
    const classes = useStyles();
    const campaignId = localStorage.getItem("active_campaign_id") || "";

    const [ballotState, setBallotState] = useState<BallotData>({
        id: "",
        candidates: [],
        token: "",
    });
    const [status, setStatus] = useState({loading: true, submitting: false});

    useEffect(() => {
        const fetchBallot = async () => {
            try {
                console.log("Fetching initial ballot...")
                const ballotData = await loadBallotData(campaignId, userId);
                console.log("Fetched initial ballot...")
                setBallotState(ballotData);
            } catch (e) {
                console.log(`Error fetching ballot. e: `, e);
            } finally {
                setStatus(s => ({...s, loading: false}));
            }

        }
        fetchBallot().catch();
    }, [campaignId, userId]);

    const onElect = async (id: string) => {
        console.log("Elected id: ", id);
        setStatus(s => ({...s, loading: true}));
        postBallotVote(campaignId, userId, ballotState.id, id, ballotState.token)
            .then((r) => {
                console.log(`Ballot with id ${ballotState.id} is posted. Result ${r}`);
            }, (e) => {
                console.log(`Ballot with id ${ballotState.id} cannot be posted. Error: ${e}`);
            });

        try {
            console.log("Fetching new ballot...");
            const ballot = await loadBallotData(campaignId, userId);
            console.log(`Ballot loaded: ${JSON.stringify(ballot, null, 2)}`);
            setBallotState(ballot);
        } catch (e) {
            console.error(`Ballot cannot be loaded. Error: ${e}`);
        } finally {
            setStatus(s => ({...s, loading: false}))
        }
    }

    return (
        <>
            <NavBar/>
            <Grid container className={classes.viewRoot} direction={"column"}>
                <Grid item className={classes.containerGrid}>
                    <Ballot ballotId={ballotState.id} candidates={ballotState.candidates} onElect={onElect}/>
                </Grid>
                {
                    status.loading && <Grid item className={classes.progressContainer}>
                        <CircularProgress color="secondary"/>
                    </Grid>
                }
            </Grid>
        </>

    )
}
