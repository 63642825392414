import Typography from "@material-ui/core/Typography";
import React, {useState} from "react";
import Grid, {GridSpacing} from '@material-ui/core/Grid';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            justifyContent: "center",
            borderStyle: "none solid none solid",
            borderColor: "black",
            borderWidth: "medium",
            paddingBottom: theme.spacing(2),
            width: "24ch",
            minHeight: "100%"
        },
        avatarDiv: {
            display: "flex",
            justifyContent: "center",
            marginBottom: theme.spacing(3)
        },
        avatar: {
            margin: "auto",
            width: theme.spacing(19),
            height: theme.spacing(21),
            borderStyle: "solid",
            borderWidth: "thin",
            border: "black",
            objectFit: "cover"
        },
        nameContainer: {
            borderStyle: "solid none solid none",
            borderWidth: "medium",
            border: "black",
            width: theme.spacing(17),
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(3)
        },
        name: {
            textTransform: "uppercase"
        },
        markerContainer: {
            borderStyle: "solid none solid none",
            borderWidth: "medium",
            border: "black",
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            marginRight: theme.spacing(3),
            marginLeft: theme.spacing(3)
        },
        marker: {
            border: "black",
            borderStyle: "solid",
            borderWidth: "medium",
            borderRadius: "50%",
            width: theme.spacing(10),
            height: theme.spacing(10),
            margin: "auto",
        },
        content: {
            paddingTop: theme.spacing(0.1),
            paddingBottom: theme.spacing(3),
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        profile: {
            marginBottom: theme.spacing(3)
        },
        filler: {
            minHeight: "0",
            flex: 1
        },
        stamp: {
            maxWidth: "100%",
            maxHeight: "100%",
        }
    }),
);


export interface CandidateProps {
    id: string,
    ballotId: string,
    name: string,
    avatar?: string,
    selected: boolean,
    enabled: boolean,
    onSelected?: (id: string) => void
}

export default function Candidate(props: CandidateProps) {
    const classes = useStyles();
    const [candidateState, setCandidateState] = useState({
        ballotId: props.ballotId,
        isMouseOverMarker: false,
        rotation: Math.random() * 360,
        translation: Math.random() * 6 + 2,
    });

    if (props.ballotId !== candidateState.ballotId) {
        setCandidateState({
            ballotId: props.ballotId,
            isMouseOverMarker: false,
            rotation: Math.random() * 360,
            translation: Math.random() * 6 + 2
        });
    }

    const isResponsive = () => props.enabled && !props.selected;

    const onMarkerEnter: React.MouseEventHandler<HTMLElement> = (e) => {
        isResponsive() &&
        setCandidateState({
            ...candidateState, isMouseOverMarker: true,
            rotation: Math.random() * 360,
            translation: Math.random() * 10 + 2
        });

    };
    const onMarkerLeave = () => {
        isResponsive() &&
        setCandidateState({
            ...candidateState,
            isMouseOverMarker: false
        });
    };

    const onSelection = () => {
        isResponsive() && props.onSelected && props.onSelected(props.id);
    }

    return (
        <Grid className={classes.root} container direction={"column"} justify={"center"}>
            <Grid container className={classes.content} direction={"column"} justify={"center"}>
                <Grid item className={classes.avatarDiv}>
                    <img alt={props.avatar} src={props.avatar} className={classes.avatar}/>
                </Grid>
                <Grid>
                    <Box>
                        <Typography className={classes.name} variant={"h6"} align={"center"}>{props.name}</Typography>
                    </Box>
                </Grid>
            </Grid>
            <Grid item className={classes.filler}/>
            <Grid item className={classes.markerContainer}>
                <Box className={classes.marker} onMouseEnter={onMarkerEnter} onMouseLeave={onMarkerLeave}>
                    <img alt={props.name}
                         style={{transform: `rotate(${candidateState.rotation}deg) translateX(${candidateState.translation}px)`}}
                         hidden={!props.selected && !candidateState.isMouseOverMarker}
                         className={classes.stamp} src={"/yes.png"}
                         onClick={onSelection}
                    />
                </Box>
            </Grid>
        </Grid>
    )
}
