import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import React, {useCallback, useState} from "react";
import {useDropzone} from "react-dropzone";
import NavBar from "../layout/navbar";
import {Button, TextField, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {CirclePicker, ColorResult, GithubPicker} from 'react-color';
import {CampaignsService, CandidatesService} from "../api";
import {stat} from "fs";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formElement: {
            margin: "10px 10px"
        },
        dropzone: {
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "20px",
            borderWidth: "2px",
            width: theme.spacing(10),
            height: theme.spacing(10),
            borderColor: "#eeeeee",
            borderStyle: "dashed",
            backgroundColor: "#fafafa",
            primaryColor: "#bdbdbd",
            outline: "none",
            transition: "border .24s ease-in-out",
            maxWidth: "100%",
            maxHeight: "100%",
        },
        dropzoneText: {
            display: "flex",
            "justify-content": "center",
            "align-items": "center",
            width: "100%",
            height: "100%",
            textAlign: "center"
        },
        avatarImg: {
            margin: "auto",
            width: theme.spacing(19),
            height: theme.spacing(21),
            borderStyle: "solid",
            borderWidth: "thin",
            border: "black",
            objectFit: "cover",
        },
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
            justifyContent: "center",
            alignItems: "center",
            padding: theme.spacing(1),
            width: "24ch",
        },
        colorContainer: {
            '& > *': {
                margin: theme.spacing(0.5),
            },
            justifyContent: "center",
            alignItems: "center",
            padding: theme.spacing(1),
            width: "24ch",
        },
        containerGrid: {
            width: "fit-content"
        },
        progressContainer: {
            position: 'absolute',
            justifyContent: "center",
            alignItems: "center",
        },
        table: {
            backgroundColor: theme.palette.primary.main,
            minWidth: 650,
        },
    }),
);

function MyDropzone({onSelected}: { onSelected: (s: string) => void }) {
    const classes = useStyles();
    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file: File) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result
                console.log(binaryStr)
                onSelected(binaryStr as string);
            }
            reader.readAsDataURL(file)
        })

    }, [onSelected])
    const {getRootProps, getInputProps} = useDropzone({onDrop})

    return (
        <div {...getRootProps()} className={classes.dropzone}>
            <input {...getInputProps()} />
            <div className={classes.dropzoneText}>
                Kampanya Resmi
            </div>
        </div>
    )
}

export interface NewCampaignProps {
    userId: string;
    onSaveBegin?: () => void;
    onSaveEnd?: (id?: string, e?: Error) => void;
}

export default function NewCampaignView({userId, onSaveBegin, onSaveEnd}: NewCampaignProps) {
    const classes = useStyles();
    const [state, setState] = useState({
        name: "",
        avatar: undefined as unknown as string,
        primaryColor: "",
        secondaryColor: "",
        showPrimaryPicker: false,
        showSecondaryPicker: false,
        saving: false
    });


    const onSave = async () => {
        console.log("Creating new campaign...");

        setState(s => ({...s, saving: true}))

        try {
            onSaveBegin && onSaveBegin();
            const response = await CampaignsService.postCampaigns({
                // @ts-ignore
                user: userId, requestBody: {
                    name: state.name,
                    avatar: state.avatar,
                    primaryColor: state.primaryColor,
                    secondaryColor: state.secondaryColor
                }
            });
            console.log("Created new campaign with id:", response.id);
            setState(s => ({...s, name:"", avatar: "", saving: false}))

            onSaveEnd && onSaveEnd(response.id);
        } catch (e) {
            console.log(`Failed to save new campaign. ${e}`, e);
            setState(s => ({...s, saving: false}))
            onSaveEnd && onSaveEnd(undefined, e);
        }
    }

    return (
        <>
            <Grid container className={classes.root} direction={"column"}
                  style={state.saving ? {pointerEvents: "none", opacity: "0.4"} : {}}>
                <Grid item>
                    <TextField id="campaingNameField" value={state.name} variant="outlined" size="small" label="Kampanya ismi"
                               onChange={(v) => setState({...state, name: v.target.value})}/>
                </Grid>
                <Grid item>
                    <div style={{position: "relative"}}>
                        {!state.avatar && <MyDropzone onSelected={(i) => {
                            setState(s => ({...s, avatar: i}))
                        }}/>}
                        {state.avatar && <img className={classes.avatarImg} src={state.avatar!} onClick={
                            () => setState({...state, avatar: ""})
                        } alt={"Silmek için tıkla"}/>}
                    </div>
                </Grid>
                <Grid item container className={classes.colorContainer} direction={"row"}>
                    <Grid item>
                        <Button style={{backgroundColor: state.primaryColor}}
                                onClick={() => setState(s => ({
                                    ...s,
                                    showPrimaryPicker: !s.showPrimaryPicker,
                                    showSecondaryPicker: false
                                }))}> Ana
                            Renk
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button style={{backgroundColor: state.secondaryColor}}
                                onClick={() => setState(s => ({
                                    ...s,
                                    showSecondaryPicker: !s.showSecondaryPicker,
                                    showPrimaryPicker: false
                                }))}> İkinci
                            Renk
                        </Button>
                    </Grid>
                </Grid>
                <Grid item>
                    {state.showPrimaryPicker && <CirclePicker
                        color={state.primaryColor}
                        // triangle={"hide"}
                        onChangeComplete={(color: ColorResult) => {
                            setState(s => ({...s, showPrimaryPicker: false, primaryColor: color.hex}))
                        }}
                    />}
                    {state.showSecondaryPicker && <CirclePicker
                        width={"132px"}
                        circleSize={20} circleSpacing={2}
                        colors={['#B80000', '#DB3E00', '#FCCB00', '#008B02', '#006B76', '#1273DE', '#004DCF', '#5300EB', '#EB9694', '#FAD0C3', '#FEF3BD', '#C1E1C5', '#BEDADC', '#C4DEF6', '#BED3F3', '#D4C4FB']
                        }
                        color={state.secondaryColor}
                        // triangle={"hide"}
                        onChangeComplete={(color: ColorResult) => {
                            setState(s => ({...s, showSecondaryPicker: false, secondaryColor: color.hex}))
                        }}
                    />}
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={onSave}>{state.saving ? "Kaydediyor" : "Kaydet"}</Button>
                </Grid>
            </Grid>
        </>
    )
}
