import React, {Component, useEffect, useState} from 'react';
import './App.css';

import NavBar from './layout/navbar'
import BallotView from "./election/ballotView";
import {MuiThemeProvider} from "@material-ui/core";
import applicationTheme, {themeFor, themeProvider} from "./applicationTheme";
import {
    Switch,
    Route,
} from "react-router-dom";
import FormPropsTextFields from "./nominees/createNominee";
import StandingsView from "./standings/standingsView";
import {CAMPAIGN_ID, USER_ID} from "./common/network";
import {CampaignListView} from "./campaings/campaignListView";
import {CampaignCandidatesView} from "./campaings/campaignCandidatesView";

export function App() {
    const activeCampaign = localStorage.getItem("active_campaign_id");
    console.log(`Initial active theme is ${activeCampaign}`);

    const [theme, setTheme] = useState(applicationTheme);

    useEffect(() => {
        console.log(`Active campaign is ${activeCampaign}`);

        themeProvider().then((theme) => {
            console.log(`Theme is loaded for campaign ${activeCampaign}. Primary color: ${theme.palette.primary.main}`)
            setTheme(theme)
        }, (e) => console.error(`Failed to load theme. `, e));
    }, [activeCampaign]);

    return (
        <MuiThemeProvider theme={theme}>
            <div lang={"tr"}>
                <Switch>
                    <Route path={"/election"}>
                        <BallotView campaignId={CAMPAIGN_ID} userId={USER_ID}/>
                    </Route>
                    <Route path={"/rankings"}>
                        <StandingsView campaignId={CAMPAIGN_ID}/>
                    </Route>
                    <Route path={"/campaigns/:campaignId"}>
                        <CampaignCandidatesView user={USER_ID}/>
                    </Route>
                    <Route path={"/campaigns"}>
                        <CampaignListView user={USER_ID} onCampaignChange={(campaign => {
                            const theme = themeFor({
                                primaryColor: campaign.primaryColor,
                                secondaryColor: campaign.secondaryColor
                            });

                            console.log(`Theme is loaded for campaign ${activeCampaign}. Primary color: ${theme.palette.primary.main}`);
                            setTheme(theme);
                        })}/>
                    </Route>
                    <Route path={"/nomination"}>
                        <FormPropsTextFields/>
                    </Route>
                    <Route path={"/"}>
                        <NavBar/>
                        Merhaba poğaçacı!
                    </Route>
                </Switch>
            </div>
        </MuiThemeProvider>
    )
}

export default App
