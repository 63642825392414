import React, {useEffect, useState} from "react";
import {Campaign, CampaignsService} from "../api";
import {
    Avatar,
    IconButton,
    Paper,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import NavBar from "../layout/navbar";
import Grid from "@material-ui/core/Grid";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {AddCircle, DeleteForeverOutlined, EditOutlined} from "@material-ui/icons";
import NewCampaignView from "./newCampaign";
import {Link} from "react-router-dom";

export interface CampaignListViewProps {
    user: string;
    offset?: string;
    onCampaignChange?: (campaign: Campaign) => void;
}

interface CampaignsState {
    campaigns: Campaign[];
    nextPageToken?: string;
    deleting: boolean;
    selected?: Campaign;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        viewRoot: {
            backgroundColor: theme.palette.secondary.main,
            justifyContent: "center",
            alignItems: "center",
            padding: theme.spacing(1),
            position: 'relative'
        },
        containerGrid: {
            width: "fit-content"
        },
        table: {
            backgroundColor: theme.palette.primary.main,
            minWidth: 650,
        },
    }),
);

export function CampaignListView(props: CampaignListViewProps) {
    const classes = useStyles();
    const [listState, setListState] = useState<CampaignsState>({campaigns: [], deleting: false});
    const [addState, setAddState] = useState({showAdd: false})

    const deleteCampaign = async (user: string, campaignId: string) => {
        setListState(s => ({...listState, deleting: true}));
        try {
            await CampaignsService.deleteCampaignsCampaignId({campaignId, user});
        } catch (e) {
            console.log(`Error deleting campaign with id ${campaignId}.`, e);
        }
        setListState(s => ({...listState, deleting: false}));
    }

    const loadCampaigns = async (user: string, offset?: string) => {
        try {
            const campaignsResponse = await CampaignsService.getCampaigns({user});
            const activeCampaignId = localStorage.getItem("active_campaign_id");
            const selected = campaignsResponse.campaigns.find((c) => c.id === activeCampaignId);
            setListState(s => ({...s, selected, ...campaignsResponse}));
        } catch (e) {
            console.log(`Failed to load campaigns for user ${user} and offset ${offset}`);
        }
    }

    useEffect(() => {
        loadCampaigns(props.user, props.offset).finally();
    }, [props.offset, props.user]);

    return (<>
        <NavBar/>
        <Grid container className={classes.viewRoot} direction={"column"}>
            <Grid item className={classes.containerGrid}>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell align="left">Avatar</TableCell>
                                <TableCell align="left">Ad</TableCell>
                                <TableCell align="left">Ana Renk</TableCell>
                                <TableCell align="left">Yardımcı Renk</TableCell>
                                <TableCell align="left">Düzenle</TableCell>
                                <TableCell align="left">Sil</TableCell>
                                <TableCell align="left">Aktif</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listState.campaigns.map((row, index) => (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell align="right">
                                        <Avatar variant={"rounded"} src={row.avatar}/>
                                    </TableCell>
                                    <TableCell align="left">{row.name}</TableCell>
                                    <TableCell align="left">
                                        <Typography variant={"caption"}
                                                    style={{backgroundColor: row.primaryColor}}>{row.primaryColor}</Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography variant={"caption"}
                                                    style={{backgroundColor: row.secondaryColor}}>{row.secondaryColor}</Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <IconButton to={`/campaigns/${row.id}`} color="secondary"
                                                    aria-label="edit campaign" component={Link}
                                                    style={listState.deleting ? {
                                                        pointerEvents: "none",
                                                        opacity: "0.4"
                                                    } : {}}>
                                            <EditOutlined/>
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align="left">
                                        <IconButton color="secondary" aria-label="delete campaign" component="span"
                                                    style={listState.deleting ? {
                                                        pointerEvents: "none",
                                                        opacity: "0.4"
                                                    } : {}}>
                                            <DeleteForeverOutlined
                                                onClick={() => deleteCampaign(props.user, row.id).then(() => loadCampaigns(props.user))}/>
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Radio checked={row === listState.selected}
                                               onChange={() => {
                                                   setListState(s => ({...s, selected: row}));
                                                   localStorage.setItem("active_campaign_id", row.id);
                                                   props.onCampaignChange && props.onCampaignChange(row);
                                               }}/>
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <IconButton color="secondary" aria-label="upload picture" component="span">
                                    <AddCircle onClick={() => setAddState(s => ({...s, showAdd: !s.showAdd}))}/>
                                </IconButton>
                            </TableRow>
                        </TableBody>
                    </Table>
                    {addState.showAdd && <NewCampaignView userId={props.user} onSaveEnd={() => {
                        setAddState({
                            ...addState,
                            showAdd: false
                        });
                        loadCampaigns(props.user, props.offset).finally();
                    }}/>}
                </TableContainer>
            </Grid>
        </Grid>

    </>)
}
