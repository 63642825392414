import {createMuiTheme, ThemeOptions} from "@material-ui/core";
import {Campaign, CampaignsService} from "./api";

const defaultOptions: ThemeOptions = {
    palette: {
        primary: {
            main: "#E7E5D0",

        },
        secondary: {
            main: "#630000"
        },
    },
    typography: {
        h5: {
            fontFamily: "Helvetica",
            fontWeight: "bolder"
        },
        h6: {
            fontFamily: "Helvetica",
            fontWeight: "bolder"
        }
    }
};

const applicationTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#E7E5D0",

        },
        secondary: {
            main: "#630000"
        },
    },
    typography: {
        h5: {
            fontFamily: "Helvetica",
            fontWeight: "bolder"
        },
        h6: {
            fontFamily: "Helvetica",
            fontWeight: "bolder"
        }
    }
});

export const themeProvider = async () => {
    const options = {...defaultOptions};
    const campaignId = localStorage.getItem("active_campaign");
    if (campaignId) {
        const campaign = await CampaignsService.getCampaignsCampaignId({ campaignId: campaignId });
        options.palette!.primary = { main: campaign.primaryColor };
        options.palette!.secondary = { main: campaign.secondaryColor };
    }
    return createMuiTheme(options);
}

export const themeFor = ({primaryColor, secondaryColor}: {primaryColor: string, secondaryColor: string}) => {
    const options = {...defaultOptions};
        options.palette!.primary = { main: primaryColor };
        options.palette!.secondary = { main: secondaryColor };
    return createMuiTheme(options);
}

export default applicationTheme;
