import Grid from "@material-ui/core/Grid";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import NavBar from "../layout/navbar";
import React, {useEffect, useState} from "react";
import {USER_ID} from "../common/network";

import {
    Avatar,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import {CampaignsService, CandidatesService} from "../api";

interface CandidateInfo {
    rank: number;
    name: string;
    candidateId: string;
    avatar: string;
    rating: number;
    voteCount: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        viewRoot: {
            backgroundColor: theme.palette.secondary.main,
            justifyContent: "center",
            alignItems: "center",
            padding: theme.spacing(1),
            position: 'relative'
        },
        containerGrid: {
            width: "fit-content"
        },
        progressContainer: {
            position: 'absolute',
            justifyContent: "center",
            alignItems: "center",
        },
        table: {
            backgroundColor: theme.palette.primary.main,
            minWidth: 650,
        },
    }),
);

const loadCandidates = async (campaignId: string): Promise<CandidateInfo[]> => {
    const campaignCandidates = await CampaignsService.getCampaignsCampaignIdCandidates({campaignId, sort: "rating"});

    const candidates = await CandidatesService.getCandidates({
        user: USER_ID,
        ids: campaignCandidates.candidates.map(c => c.candidateId).join(',')
    });

    const candidateInfoList = campaignCandidates.candidates.map((cc, index): CandidateInfo | null => {
        const candidate = candidates.candidates.find(c => c.id === cc.candidateId);
        if (!candidate) {
            console.log(`Candidate with id ${cc.candidateId} cannot be found in candidates list`);
            return null;
        }

        return {
            candidateId: cc.candidateId,
            name: candidate.name,
            avatar: candidate.avatar,
            rank: index + 1,
            rating: cc.rating,
            voteCount: cc.voteCount
        }
    }).filter(c => !!c);

    return candidateInfoList as CandidateInfo[];
}

export default function StandingsView({}: { campaignId: string }) {
    const campaignId = localStorage.getItem("active_campaign_id") || "";
    const classes = useStyles();
    const [standingsState, setStandingsState] = useState({
        candidates: [] as CandidateInfo[],
        loading: true,
    });

    useEffect(() => {
        loadCandidates(campaignId).then((candidates) => {
                setStandingsState(s => ({...s, candidates, loading: false}));
            },
            (error) => {
                setStandingsState(s => ({...s, candidates: [], loading: false}));
            });
    }, [campaignId]);
    return (
        <>
            <NavBar/>
            <Grid container className={classes.viewRoot} direction={"column"}>
                <Grid item className={classes.containerGrid}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell align="left">Avatar</TableCell>
                                    <TableCell align="left">Name</TableCell>
                                    <TableCell align="left">Rating</TableCell>
                                    <TableCell align="left">Votes</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {standingsState.candidates.map((row) => (
                                    <TableRow key={row.candidateId}>
                                        <TableCell component="th" scope="row">
                                            {row.rank}
                                        </TableCell>
                                        <TableCell align="right">
                                            <Avatar src={row.avatar}/>
                                        </TableCell>
                                        <TableCell align="left">{row.name}</TableCell>
                                        <TableCell align="left">{row.rating}</TableCell>
                                        <TableCell align="left">{row.voteCount}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </>
    )
}
