/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Ballot } from '../models/Ballot';
import type { BallotVoteRequest } from '../models/BallotVoteRequest';
import type { Campaign } from '../models/Campaign';
import type { CampaignCandidate } from '../models/CampaignCandidate';
import { request as __request } from '../core/request';

export class CampaignsService {

    /**
     * List campaigns
     * @returns any Example response
     * @throws ApiError
     */
    public static async getCampaigns({
        user,
    }: {
        user?: string,
    }): Promise<{
        campaigns: Array<Campaign>,
        nextPageToken?: string,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/campaigns`,
            query: {
                'user': user,
            },
        });
        return result.body;
    }

    /**
     * Create campaign
     * @returns Campaign Created
     * @throws ApiError
     */
    public static async postCampaigns({
        user,
        requestBody,
    }: {
        user?: string,
        requestBody?: Campaign,
    }): Promise<Campaign> {
        const result = await __request({
            method: 'POST',
            path: `/campaigns`,
            query: {
                'user': user,
            },
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Delete campaign
     * @returns void
     * @throws ApiError
     */
    public static async deleteCampaignsCampaignId({
        campaignId,
        user,
    }: {
        campaignId: string,
        user?: string,
    }): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/campaigns/${campaignId}`,
            query: {
                'user': user,
            },
            errors: {
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Get campaign
     * @returns Campaign OK
     * @throws ApiError
     */
    public static async getCampaignsCampaignId({
        campaignId,
    }: {
        campaignId: string,
    }): Promise<Campaign> {
        const result = await __request({
            method: 'GET',
            path: `/campaigns/${campaignId}`,
            errors: {
                404: `Not Found`,
            },
        });
        return result.body;
    }

    /**
     * Register candidate to campaign
     * @returns any OK
     * @throws ApiError
     */
    public static async putCampaignsCampaignIdCandidatesCandidateId({
        campaignId,
        candidateId,
        user,
    }: {
        campaignId: string,
        candidateId: string,
        user?: string,
    }): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/campaigns/${campaignId}/candidates/${candidateId}`,
            query: {
                'user': user,
            },
        });
        return result.body;
    }

    /**
     * Delete campaign registration
     * @returns any OK
     * @throws ApiError
     */
    public static async deleteCampaignsCampaignIdCandidatesCandidateId({
        campaignId,
        candidateId,
    }: {
        campaignId: string,
        candidateId: string,
    }): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/campaigns/${campaignId}/candidates/${candidateId}`,
        });
        return result.body;
    }

    /**
     * Create ballot
     * @returns Ballot Created
     * @throws ApiError
     */
    public static async postCampaignsCampaignIdBallots({
        campaignId,
        user,
    }: {
        campaignId: string,
        user?: string,
    }): Promise<Ballot> {
        const result = await __request({
            method: 'POST',
            path: `/campaigns/${campaignId}/ballots`,
            query: {
                'user': user,
            },
        });
        return result.body;
    }

    /**
     * Vote the ballot
     * @returns void
     * @throws ApiError
     */
    public static async postCampaignsCampaignIdBallotsBallotIdVote({
        campaignId,
        ballotId,
        requestBody,
        user,
    }: {
        campaignId: string,
        ballotId: string,
        requestBody: BallotVoteRequest,
        user?: string,
    }): Promise<void> {
        const result = await __request({
            method: 'POST',
            path: `/campaigns/${campaignId}/ballots/${ballotId}/vote`,
            query: {
                'user': user,
            },
            body: requestBody,
        });
        return result.body;
    }

    /**
     * Your GET endpoint
     * @returns any Example response
     * @throws ApiError
     */
    public static async getCampaignsCampaignIdCandidates({
        campaignId,
        candidateIds,
        sort,
    }: {
        campaignId: string,
        candidateIds?: string,
        sort?: 'rating' | 'votes',
    }): Promise<{
        candidates: Array<CampaignCandidate>,
        nextPageToken?: string,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/campaigns/${campaignId}/candidates`,
            query: {
                'candidateIds': candidateIds,
                'sort': sort,
            },
        });
        return result.body;
    }

}