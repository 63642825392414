import React, {useState} from 'react';
import TextField from '@material-ui/core/TextField';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {DropzoneArea} from "material-ui-dropzone";
import Container from '@material-ui/core/Container';
import {Avatar, Button, Paper} from "@material-ui/core";
import Candidate from "../components/candidate";
import NavBar from "../layout/navbar";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1),
                width: '25ch',
            },
        },
        small: {
            width: theme.spacing(3),
            height: theme.spacing(3),
        },
        large: {
            width: theme.spacing(7),
            height: theme.spacing(7),
        },
        ballot: {
            display: "flex",
            margin: theme.spacing(3),
            width: "fit-content"
        },
        candi: {
            margin: theme.spacing(1),
            width: '22ch'
        }
    }),
);

export default function FormPropsTextFields(props: { avatarUrl?: string }) {
    const classes = useStyles();

    const [nomineeState, setNomineeState] = useState({name: "", avatar: props.avatarUrl});

    return (
        <div>
            <NavBar/>
            <form className={classes.root} noValidate autoComplete="off">
                <Container maxWidth="sm">
                    <DropzoneArea
                        onChange={async (files) => {
                            console.log('Files:', files);
                            if (files.length > 0) {
                                const reader = new FileReader();
                                if (reader)
                                    reader.onloadend = ((e) => {
                                        console.log(e.target?.result);
                                        setNomineeState({...nomineeState, avatar: e.target?.result as string});
                                    });

                                reader.readAsDataURL(files[0]);

                            }
                        }}
                        filesLimit={1}
                        dropzoneText={""}
                        showPreviews={false}
                        showPreviewsInDropzone={false}
                        useChipsForPreview={true}
                    />
                    <TextField size="small" variant={"standard"} required id="standard-required" onChange={
                        event => setNomineeState({avatar: nomineeState.avatar, name: event.target.value})
                    }/>
                    <Button variant="contained">Nominate</Button>
                </Container>

            </form>

            <Paper className={classes.ballot}>
                <div className={classes.candi}><Candidate enabled={true} name={nomineeState.name} avatar={nomineeState.avatar}/></div>
                <div className={classes.candi}><Candidate enabled={true} name={nomineeState.name} avatar={nomineeState.avatar}/></div>
                <div className={classes.candi}><Candidate enabled={true} name={nomineeState.name} avatar={nomineeState.avatar}/></div>
            </Paper>
        </div>
    );
}
