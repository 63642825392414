import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Button} from "@material-ui/core";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
            root: {
                flexGrow: 1,
                position: "sticky",
                top: 0,
                zIndex: 9999
            },
            title: {},
            menuGroup: {
                flexGrow: 1,
            },
            logo: {
                width: theme.spacing(4),
                height: theme.spacing(4),
                marginRight: theme.spacing(1)
            },
        }
    )
);

const NavBar = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <AppBar position="sticky">
                <Toolbar>
                    <img className={classes.logo} alt={"evet"} src={"/yes.png"}/>
                    <Typography className={classes.title} variant="h5">
                        Mutlak Seçim Sistemi
                    </Typography>
                    <div className={classes.menuGroup}>
                        <Button component={Link} to={"/election"} color="inherit">Seç</Button>
                        <Button component={Link} to={"/rankings"} color="inherit">Sonuçlar</Button>
                        <Button component={Link} to={"/campaigns"} color="inherit">Kampanyalar</Button>
                        <Button component={Link} to={"/nomination"} color="inherit">Aday Göster</Button>
                    </div>
                    <Button component={Link} to={"/"} color="inherit">Giriş</Button>
                </Toolbar>
            </AppBar>
        </div>
    )
}
export default NavBar;
