import {Avatar, Card, Paper} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import {Image} from "@material-ui/icons";

interface CandidateProps {
    name: string,
    enabled: boolean,
    avatar?: string
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            justifyContent: "center",
            borderStyle: "none solid none solid",
            borderColor: "black",
            borderWidth: "medium"
        },
        avatarDiv: {
            display: "flex",
            justifyContent: "center",
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3)
        },
        avatar: {
            margin: "auto",
            width: theme.spacing(19),
            height: theme.spacing(21),
            borderStyle: "solid",
            borderWidth: "medium",
            border: "gray",
            objectFit: "cover"
        },
        nameContainer: {
            borderStyle: "solid none solid none",
            borderWidth: "medium",
            border: "black",
            width: theme.spacing(17),
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(3)
        },
        name: {
            textTransform: "uppercase"
        },
        markerContainer: {
            borderStyle: "solid none solid none",
            borderWidth: "medium",
            border: "black",
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            marginRight: theme.spacing(3),
            marginLeft: theme.spacing(3)
        },
        marker: {
            border: "black",
            borderStyle: "solid",
            borderWidth: "medium",
            borderRadius: "50%",
            width: theme.spacing(10),
            height: theme.spacing(10),
            margin: "auto",
        },
        content: {
            paddingTop: theme.spacing(0.1),
            paddingBottom: theme.spacing(3),
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        profile: {
            marginBottom: theme.spacing(3)
        }
    }),
);

export default function Candidate(props: CandidateProps) {
    const classes = useStyles();
    return (

        <div className={classes.paper}>
            <div className={classes.content}>
                <div className={classes.profile}>
                    <div className={classes.avatarDiv}>
                        <img alt="Remy Sharp" src={props.avatar} className={classes.avatar}/>
                    </div>
                    <div>
                        <Typography className={classes.name} variant={"h5"} align={"center"}>{props.name}</Typography>
                    </div>
                </div>
                <div className={classes.markerContainer}>
                    <div className={classes.marker}/>
                </div>
            </div>
        </div>
    )
}
